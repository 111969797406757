.custom-ant {
  //  进度条
  .ant-progress {
    display: flex;
    align-items: center;

    .ant-progress-outer {
      display: flex;
      align-items: center;
      //width: 196px;
      //height: 11px;

      .ant-progress-inner {
        //background: #2C4672;
        border-radius: 26px;
      }
    }

    .ant-progress-text {
      width: auto;
      margin-left: 10px;
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      //color: #FFFFFF;
    }
  }

  //  carousel样式
  .ant-carousel {
    height: 412px;
  }

  //  select组件样式
  .ant-select {
    width: 100%;
    text-align: center;
    &:not(.ant-select-customize-input) .ant-select-selector {
      font-family: 'PingFang SC';
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      border: none;
      border-radius: 17px;
    }
  }

  //  picker组件样式
  .ant-picker {
    //height: 36px;
    //background: #161C25;
    border: 1px solid #434343;
    border-radius: 17px;

    .ant-picker-input > input {
      font-family: 'DINOffcPro';
      font-size: 16px;
      line-height: 19px;
      //color: #DBDCDD;

      &::placeholder {
        font-family: 'DINOffcPro';
        font-size: 16px;
        line-height: 19px;
        color: #43516C;
      }
    }

    .ant-picker-range-separator, .ant-picker-suffix {
      svg {
        fill: #5C6067;
      }
    }
  }

  //  table组件样式
  .ant-table-wrapper {
    .ant-table-thead {
      > tr > th {
        border-bottom: 1px solid transparent;
      }
      .ant-table-cell {
        //background-color: #1E2531;
        font-family: 'PingFang SC';
        font-weight: bolder;
        &:extend(.fontSize .font18);
        line-height: 18px;
        text-align: center;
        text-transform: capitalize;
        //color: #DBDCDD;
        &:before {
          display: none;
        }
      }
    }
    .ant-table {
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            border: none;
            font-family: 'PingFang SC';
            &:extend(.fontSize .font18);
            line-height: 18px;
            text-align: center;
            text-transform: capitalize;
            //color: #DBDCDD;

            &:before {
              display: none;
            }
          }

          &:hover {
            .ant-table-cell {
              //background-color: #1E2531;
            }
          }
        }

        .ant-table-placeholder {

          .ant-table-cell {
            border: none;
            //.ant-empty-image {
            //  svg {
            //    g {
            //      ellipse {
            //        fill: #22252C;
            //      }
            //
            //      g {
            //        stroke: #363738;
            //
            //        path {
            //          &:first-of-type {
            //            fill: #1E2531;
            //          }
            //
            //          &:last-of-type {
            //            fill: #262626;
            //          }
            //        }
            //      }
            //    }
            //  }
            //}
            .ant-empty-description {
              font-family: 'PingFang SC';
              font-weight: 500;
              &:extend(.fontSize .font18);
              line-height: 18px;
              text-transform: capitalize;
              color: #56595E;
            }
          }
        }
      }
    }
  }


  //  pagination组件样式
  .ant-pagination {
    margin-top: 14px;
    justify-content: center;

    .ant-pagination-item {
      border: 1px solid transparent;
      background-color: transparent;

      a {
        line-height: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }

    .ant-pagination-item-active {
      //background-color: #1E2531;
    }

    .ant-pagination-item-ellipsis {
      color: #1677ff!important;
    }

    .ant-pagination-prev, .ant-pagination-next {
      padding: 0 10px;
      margin: 0 20px 0 10px;
      text-align: center;
      border: 1px solid transparent;

      a {
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 22px;
        //color: #FFFFFF;
      }
    }
  }

  //  form组件样式
  .ant-form-item {
    margin-bottom: 28px;

    .ant-form-item-label {
      margin-top: 4px;
      //margin-bottom: 7px;

      label {
        font-family: 'PingFang SC';
        font-weight: 500;
        line-height: 16px;
        &:extend(.fontSize .font18);
      }
    }

    .ant-input-affix-wrapper {
      width: 427px;
      height: 53px;
      padding: 0 0 0 11px;
      border-radius: 12px;

      input {
        padding-left: 34px;
        background-color: transparent;
        //color: #5E636B;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 16px;

        &::placeholder {
          color: #5E636B;
        }
      }
    }

    .ant-form-item-explain {
      color: #adb6c4!important;
      font-family: Montserrat!important;
      font-size: 12px!important;
      font-weight: 500!important;
      text-align: right!important;

      .ant-form-item-explain-error {
        color: #a61d24;
      }
    }
  }

  //  checkbox组件样式
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      border: 1px solid #434343;
      border-collapse: initial;
      border-radius: 2px;
      transition: all .3s;
      background-color: transparent;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #1a55ef!important;
        border-color: #707070!important;
      }
    }
  }

  //  drawer 组件样式
  &.ant-drawer {
    .ant-drawer-content-wrapper {
      width: 434px !important;
      .ant-drawer-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 82px 0 0;
        overflow-x: hidden;
      }
    }
  }

  //  message的分页组件样式
  &.message-pagination {
    .ant-pagination {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .ant-pagination-item {
        width: 30px;
        height: 30px;
        a {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }

      .ant-pagination-item-active {
        //color: #2C7EDC;
        border: 1px solid transparent;
      }

      .ant-pagination-prev, .ant-pagination-next {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #303030;

        svg {
          color: #808389;
        }
      }

      .ant-pagination-item-ellipsis {
        line-height: 30px;
        color: #fff;
      }
    }
  }

  //  Slider  组件样式
  .ant-slider {
    width: 452px;
    height: 4px;
    border-radius: 41px;

    .ant-slider-rail {
      //background: #383C4E;
    }

    .ant-slider-track {
      background-color: #153450;
    }

    .ant-slider-handle {
      width: 11px;
      height: 11px;
      box-shadow: none;
      border-radius: 50%;
      background: #4775F6;
    }
  }
}

//  公告的modal样式
.custom-announcement-modal {
  &.announcementModalText {
    .ant-modal {
      width: 705px!important;
      //height: 656px;
      //background: #050736;
      border-radius: 24px;
      border: 3px solid #64B1FF;

      .ant-modal-content {
        padding: 0 0 28px 0;
        background: transparent;
      }
    }
  }
  &.announcementModalPic {
    .ant-modal {
      width: 765px!important;
      padding: 0;

      .ant-modal-content {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

//  投注任务, 充值活动progress样式
.daily-task-progress, .extra-bonus-progress {
  .ant-progress {
    .ant-progress-outer {
      .ant-progress-inner {
        background: #151B25;
        border-radius: 28px;
      }
    }
    .ant-progress-text {
      font-weight: 600;
      font-size: 17px;
      line-height: 17px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

//  注册登录的modal样式
.loginRegisterModal {
  .ant-modal {
    width: 938px !important;
    height: 703px;
    border-radius: 16px;

    .ant-modal-content {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

//  用户中心progress组件样式
.personal-center-progress {
  .ant-progress {
    .ant-progress-outer {
      width: 244px!important;
      height: 12px!important;
      .ant-progress-inner {
        //background: #2b313d;
        .ant-progress-bg {
          height: 12px!important;
        }
      }
    }
    .ant-progress-text {
      width: auto;
      margin-left: 9px;
      &:extend(.fontSize .font18);
      line-height: 18px;
      text-transform: capitalize;
      //color: #DDDEDF;
    }
  }
}

//  个人头像修改modal组件样式
.modifyAvatarModal {
  .ant-modal {
    width: 590px!important;
    height: 784px;
    //background: #181E29;
    border-radius: 16px;

    .ant-modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 33px 0 0;
      background: transparent;
    }
  }
}

//  个人信息修改modal组件样式
.personalDataModal {
  .ant-modal {
    width: 479px!important;
    //height: 637px;
    //padding-bottom: 35px!important;
    //background: #0F131A;
    border-radius: 16px;
    overflow: auto;

    .ant-modal-content {
      position: relative;
      padding: 68px 0 35px 0;
      background: transparent;
    }
  }

  //  表单样式
  .personal-data-form {
    .ant-form-item {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;

      .ant-form-item-label {
        margin-bottom: 2px;

        label {
          font-family: 'PingFang SC';
          font-weight: 600;
          //font-size: 18px;
          //line-height: 18px;
          &:extend(.fontSize .font20);
          line-height: 20px;
          //color: #FFFFFF;
        }
      }

      .ant-input-affix-wrapper {
        display: flex;
        align-items: center;
        width: 379px;
        height: 36px;
        padding: 0 0 0 29px;
        //background: #262B34;
        border-radius: 8px;
        border: none;

        .ant-input-prefix {
          margin-inline-end: 0;
          font-family: 'PingFang SC';
          font-weight: 500;
          //font-size: 14px;
          //line-height: 14px;
          &:extend(.fontSize .font16);
          line-height: 16px;
          //color: rgba(255, 255, 255, 0.9);
        }

        input {
          padding-left: 34px;
          //color: #fff;
          font-family: 'PingFang SC';
          font-weight: 500;
          //font-size: 16px;
          &:extend(.fontSize .font18);
          border: none;

          &::placeholder {
            color: #5E636B;
          }
        }
      }

      .ant-input {
        width: 379px;
        height: 36px;
        padding-left: 29px;
        //background: #262B34;
        border-radius: 8px;
        border: none;
        //color: #FFF;

        &:focus {
          color: #93acd3;
        }

        &::placeholder {
          font-family: 'PingFang SC';
          font-weight: 400;
          //font-size: 14px;
          //line-height: 14px;
          &:extend(.fontSize .font16);
          line-height: 16px;
          //color: rgba(255, 255, 255, 0.3);
        }
      }

      .ant-form-item-explain {
        color: #adb6c4!important;
        font-family: Montserrat!important;
        //font-size: 10px!important;
        font-size: 12px !important;
        font-weight: 500!important;
        text-align: right!important;

        .ant-form-item-explain-error {
          color: #a61d24;
        }
      }
    }
  }
}

//  recommend/details模块的select样式
.recommend-details-select {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 36px;
  margin-top: 44px;
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      //background-color: #2C3545;
      font-weight: 400;
      &:extend(.fontSize .font18);
      color: rgba(255, 255, 255, 0.8);
      border: none;
      text-align: center;
    }
  }
}

//  Select下拉内容样式
.filterSelect {
  &.ant-select-dropdown {
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);
    .ant-select-item {
      border-radius: 12px;
      //&.ant-select-item-option-selected {
      //  background-color: rgb(26, 85, 239) !important;
      //  //border-radius: 12px;
      //  color: #FFF!important;
      //}
      //&.ant-select-item-option-active {
      //  background-color: rgb(26, 85, 239) !important;
      //  border-radius: 12px;
      //  color: #FFF!important;
      //}
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: rgb(26, 85, 239) !important;
      color: #FFF!important;
    }

  }

  //  充值组件Select下拉内容样式
  &.recharge-select-dropdown {
    width: 478px;
    .ant-select-item {
      display: flex;
      align-items: center;
      height: 44px;
      margin-bottom: 5px;
      &:hover, &.ant-select-item-option-selected {
        background-color: rgb(26, 85, 239) !important;
        .optionContent {
          span {
            color: #ffffff;
          }
        }
      }
      .optionContent {
        display: flex;
        align-items: center;

        .payLogo {
          width: 40px;
          height: 40px;
          margin-right: 9px;
        }
      }
    }
  }
}

//   recommend/details模块的table组件样式
.recommend-details-table {
  .ant-table-wrapper {
    .ant-table {
      background-color: transparent;
      border: none;
      border-radius: 8px;
      .ant-table-thead {
        .ant-table-cell {
          background-color: transparent;
          &:extend(.fontSize .font18);
          line-height: 18px;
          border: none;
        }
      }

      .ant-table-tbody {
        //border: 1px solid #303030;
        //border-radius: 8px;

        .ant-table-row {
          .ant-table-cell {
            font-weight: 500;
          }
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }

        .ant-table-placeholder {
          background-color: transparent;
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

}

//  gameHistory, fundingHistory模块的table组件样式
.game-history-table, .funding-history-table {
  .filters {
    .gameSelect {
      width: 140px;
      //height: 36px;
      margin-left: 34px;
      border: 1px solid #434343;
      border-radius: 17px;
    }
  }
  .ant-table-wrapper {
    .ant-table {
      min-width: 1158px;
      //border: 1px solid #303030;
      border-radius: 8px;
      .ant-table-thead {
        .ant-table-cell {
          &::before {
            display: block;
          }
        }
      }
      .ant-table-tbody {

        .ant-table-row {
          .ant-table-cell {
            font-weight: 500;
          }
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }

        .ant-table-placeholder {
          border-radius: 8px;
          background-color: transparent;
          &:hover {
            .ant-table-cell {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

//  gameHistory模块的select样式
.game-history-table {
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      &:extend(.fontSize .font18);
      line-height: 18px;
      border-radius: 17px;
      .ant-select-selection-placeholder {
        color: #3D4A63;
      }
    }
  }
}

//  fundingHistory模块的select样式
.funding-history-table {
  .ant-table {
    .ant-table-tbody {
      .ant-table-placeholder {
        height: 222px;
      }
    }
  }
}

//  specialTask模块select组件样式
.special-task-select {
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: initial;
      color: hsla(0,0%,100%,.8);
    }
  }
}

//   specialTask模块progress样式
.special-task-progress {
  .ant-progress {

    .ant-progress-outer {
      width: 182px;
      height: 9px;

      .ant-progress-inner {
        background: #151B25;
        border-radius: 28px;
      }
    }

    .ant-progress-text {
      font-weight: 600;
      &:extend(.fontSize .font19);
      line-height: 19px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}


//  vip模块Progress组件样式
.vip-progress {
  width: 100%;
  .ant-progress {
    margin-bottom: 44px;

    .ant-progress-outer {
      width: 283px;
      height: 12px;

      .ant-progress-inner {
        //background: #282E36;
      }
    }

    .ant-progress-text {
      &:extend(.fontSize .font16);
      line-height: 16px;
      //color: #FFFFFF;
    }
  }
}

//  奖金列表modal组件样式
.cash-back-modal {
  .ant-modal {
    width: 593px!important;
    min-height: 298px;
    //background: #181E29;
    border-radius: 16px;

    .ant-modal-content {
      position: relative;
      padding: 44px 0 26px;
      //background: #181E29;
      box-shadow: none;

      //  奖金列表Pagination组件样式
      .cash-back-pagination {
        .ant-pagination {
          display: flex;
          justify-content: flex-end;
          margin-right: 52px;
          margin-top: 16px;

          .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
            width: 40px;
            height: 41px;
            line-height: 41px;

            &.ant-pagination-prev, &.ant-pagination-next {
              border: none;
            }
            a {
              //width: 40px;
              //height: 41px;
              line-height: 41px;
            }
            //&:hover {
            //  color: #2C7EDC;
            //  border: 1px solid #2C7EDC;
            //}
          }

          //.ant-pagination-item-active {
          //  color: #2C7EDC;
          //  border: 1px solid #2C7EDC;
          //}

          .ant-pagination-prev, .ant-pagination-next {
            //width: 40px;
            svg {
              color: #808389;
            }
          }

          .ant-pagination-item-ellipsis {
            line-height: 41px;
            color: #fff;
          }
        }
      }
    }
  }
}

//  wallet的modal样式
.wallet-modal {
  .ant-modal {
    //width: 526px!important;
    //min-height: 569px;
    //max-height: 700px;
    //overflow: auto;
    width: 526px!important;
    border-radius: 16px;

    .ant-modal-content {
      position: relative;
      width: 526px!important;
      min-height: 569px;
      max-height: 700px;
      overflow: auto;
      padding: 16px 0 0;
      background: transparent;
    }
  }
}

//  recharge的Form组件样式
.recharge-form {

  .ant-form-item {
    margin-bottom: 20px;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-affix-wrapper {
      display: flex;
      width: 491px;
      height: 57px;
      line-height: 57px;
      margin: auto;
      padding: 0 0 0 12px;
      border-radius: 8px;
      border: none;
      background: transparent;

      input {
        padding: 0 40px;
        text-align: right;
        border: none;
        height: 57px;
        line-height: 57px;
      }
    }
  }

  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 66px;
      line-height: 66px;
      border-radius: 10px;

      .ant-select-selection-placeholder {
        line-height: 66px;
      }

      .ant-select-selection-item {
        height: 66px;
        line-height: 66px;
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        text-transform: capitalize;

        .optionContent {
          display: flex;
          align-items: center;

          .payLogo {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }
        }
      }

      &.ant-select-open {
        .ant-select-selection-item {
          color: rgba(255, 255, 255, .45)!important;
        }
      }

      .ant-select-arrow {
        color: #FFF;
      }
    }
  }
}

//  充值的Form样式
.withdraw-form {

  .ant-form-item {

    .ant-form-item-label {
      margin-bottom: 8px;
    }

    .ant-input {
      width: 479px;
      height: 55px;
      border-radius: 8px;
      border: none;
      &:focus {
        color: #93acd3;
      }
    }

    .ant-input-affix-wrapper {
      display: flex;
      width: 479px;
      height: 57px;
      padding: 0 0 0 12px;
      border-radius: 8px;
      border: none;

      input {
        padding: 0 40px;
        text-align: right;
        border: none;

        &::placeholder {
          color: #5E636B;
        }
      }
    }
  }
}

//  bonusCode的modal组件样式
.bonus-code-modal {
  .ant-modal {
    width: 526px!important;
    height: 524px;
    background: #181E29;
    border-radius: 16px;

    .ant-modal-content {
      padding: 0;
      background: transparent;
    }
  }
}

//  bonusCode的Form组件样式
.bonus-code-form {

  .ant-form-item {
    padding-left: 23px;

    .ant-form-item-label {
      margin-bottom: 4px;

      label {
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .ant-input {
      width: 479px;
      height: 55px;
      background: #0F131A;
      border-radius: 8px;
      border: none;
      color: #FFF;

      &:focus {
        color: #93acd3;
      }

      &::placeholder {
        color: #FFF;
      }
    }
  }
}

.mySwiper {
  //  首页优惠活动swiper
  &.banner-swiper {
    .swiper-slide {
      height: 178px;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        height: auto;
      }
    }
  }

  &.gradientDark,
  &.gradientLight {
    .swiper-slide {
      height: 240px;
    }
  }

  //  24小时奖池swiper样式
  &.hours-swiper {
    .swiper-slide {
      position: relative;
      width: 152px!important;
      height: 130px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
    }
  }

  //  recharge的Swiper样式
  &.recharge-swiper {
    margin: unset!important;
  }

  //  vip界面swiper组件样式
  &.vip-swiper {
    &.swiper {
      height: 660px;
      padding-top: 28px;

      .swiper-wrapper {
        height: auto;

        .swiper-slide {
          width: 370px;
          height: 568px;
          border-radius: 10px;
        }
      }

    }
  }

  //  充值的swiper组件样式
  &.withdraw-swiper {
    .swiper-slide {
      width: 343px!important;
      height: 143px;
      cursor: pointer;
      overflow: hidden;
    }
  }
}

