@import './less/custom-ant.less';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

i {
  display: inline-block;
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}
::-webkit-scrollbar-track {
  border-radius: 2px
}

&::-webkit-scrollbar-thumb {
  border-radius: 2px
}

@hack: true; @import '~@/assets/less/theme.less';