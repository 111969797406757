@header_background: #181F2B;
@main_background_base: #0d131c;
@main_second_background: #FFFFFF;
@statics_text_color: #FFFFFF;
@header_user_btn_color: #0f131a;
@login_modal_color: #161C25;
@tabs_bg_color: #11161D;
@input_bg_color: #222A38;
@input_third_color: #181F2B;
@input_text_color: #5E636B;
@input_label_text_color: #dcdedf;
@input_placeholder_color: #686b70;
@input_second_bg_color: #0F131A;
@input_third_bg_color: #1E2531;
@sidebar_background: #0d131c;
@sidebar_text_color: #FFFFFF;
@scrollbar_thumb_color: #2a3546;
@scrollbar_track_color: rgba(13,19,28,.8);
@select_dropdown_color: #1F1F1F;
@main_text_color: #FFFFFF;
@modal_bg_color: #181F2B;
@ant_component_color: #1E2531;
@select_text_color: #262b34;
@main_title_color: #98ABD0;
@game_type_swiper_bg: #191f2c;
@footer_bg_color: #181F2B;
@footer_text_color: #afb6c3;
@footer_text_second_color: #43516c;
@wallet_swiper_slide_bg: #222a37;
@main_fifth_bg: #1E2531;
@input_second_color: #262b34;
@modal_second_bg_color: #0F131A;
@progress_bg_color: #2b313d;
@progress_text_color: #DDDEDF;
@progress_second_bg_color: #282E36;
@main_third_bg: #152643;
@main_four_bg: #21252F;
@slider_bg_color: #383C4E;
@slider_second_bg_color: #1F3452;
@statics_text_color: #dcdedf;
@main_third_color: #2C3545;
@table_thead_cell_color: #747F9A;
@no_data_first_svg_color: #363738;
@no_data_second_svg_color: #363738;
@no_data_third_svg_color: #1E2531;
@no_data_four_svg_color: #262626;
@ant_component_text_color: #DBDCDD;
@pagination_border_color: #303030;
@pagination_hover_color: #2C7EDC;
@pagination_border_second_color: #303030;
@more_bg_color: #1c2532;
@user_info_bg_color: #12171E;
@user_info_panel_bg: linear-gradient(180deg, #171E2C 0%, #1E4893 18.41%, #161C25 38.62%, #161C25 100%);
@user_info_panel_top_bg: #15274A;
@user_info_panel_menu_bg: linear-gradient(180deg, #17449F 0%, #161E2D 100%);
@slider_outer_bg: #1E2531;
@withdraw_text_color: #43526C;
@withdraw_second_text_color: #AFB6C3;
@login_litter_text_color: #FFFFFF;
@login_second_litter_text_color: #FFFFFF;
@login_providers_bg: #1E2531;
@maintain_bg: #111923;
@message_bg: #293446;
@invite_first_bg: linear-gradient(180deg, #161D28 0%, #123B86 100%);
@invite_second_bg: linear-gradient(270.2deg, #123981 1.15%, #161E2D 97.66%);
@invite_third_bg: linear-gradient(180deg, #082353 0%, #2263F2 100%);
@invite_fourth_bg: linear-gradient(180deg, #367FEE 0%, #0C214E 100%);
@invite_fifth_bg: linear-gradient(180deg, #031851 0%, #184AAF 100%);
@invite_text_color: #ccc;
@invite_sixth_bg: #08204B;
@invite_seventh_bg: #283E74;
@invite_eighth_bg: linear-gradient(180deg, #123A86 0%, #0F1729 37.2%, #161C25 100%);
@invite_ninth_bg: linear-gradient(90deg, #212C43 0%, #2552DA 100%);
@reward_bg: linear-gradient(180deg, #2235EF 0%, #2659E6 100%);
@reward_second_bg: linear-gradient(180deg, #171D28 0%, #123A86 100%);
@reward_third_bg: #092047;
@recommend_bg: linear-gradient(180deg, rgba(22,31,44,0), #1a2534);
@special_task_bg: #2C3545;
@vip_bg: linear-gradient(180deg, #215DD1 0%, #16253E 100%);
@vip_second_bg: linear-gradient(180deg, #171E2B 0%, #1E4486 31.25%, #1E4891 66.15%, #162237 98.44%);
@vip_text_color: #193B79;
@vip_third_bg: #223759;
@vip_fourth_bg: linear-gradient(180deg, #215DD1 0%, #16253E 100%);
@bind_card_text_color: #DCDDDE;
@slider_hover_bg: transparent;
@slider_outer_hover_bg: #1E2531;
@vip_table_border_color: #3A3A3A;
@withdraw_bank_card_bg: #313131;
@withdraw_bank_box_shadow: inset 0px -2px 0px #2C2C2C, inset 0px -4px 1px #373737;
@funding_history_tabs_bg: #161C25;
@table_thead_cell_border_color: #747F9A;
@table_border_color: #303030;
@modal_third_bg_color: #050736;
@announcement_modal_btn_bg: #050736;
@slider_active_border_color: #25509B;
@message_tab_color: rgba(255, 255, 255, 0.60);
@message_content_title_color: #FFFFFF;
@message_content_desc_color: rgba(255, 255, 255, 0.50);
@message_content_border_color: rgba(255, 255, 255, 0.10);
@sidebar_linear_bg: linear-gradient(89deg, #181F29 1.43%, #1F1B48 44.9%, #251A65 73.97%, #1B1D36 98.5%);
@sidebar_linear_text_color: #AEB0B4;
@login_providers_title_color: #AFB6C3;
@invite_commission_text_color: #FFFFFF;

// 字体大小
.fontSize {
  .font12 {
    font-size: 12px;
  }
  .font14 {
    font-size: 14px;
  }
  .font15 {
    font-size: 15px;
  }
  .font16 {
    font-size: 16px;
  }
  .font17 {
    font-size: 17px;
  }
  .font18 {
    font-size: 18px;
  }
  .font19 {
    font-size: 19px;
  }
  .font20 {
    font-size: 20px;
  }
  .font21 {
    font-size: 21px;
  }
  .font22 {
    font-size: 22px;
  }
  .font24 {
    font-size: 24px;
  }
  .font26 {
    font-size: 26px;
  }
  .font28 {
    font-size: 28px;
  }
  .font30 {
    font-size: 30px;
  }
  .font33 {
    font-size: 33px;
  }
  .font34 {
    font-size: 34px;
  }
  .font36 {
    font-size: 36px;
  }
  .font38 {
    font-size: 38px;
  }
  .font40 {
    font-size: 40px;
  }
  .font52 {
    font-size: 52px;
  }
  .font56 {
    font-size: 56px;
  }
}
